import { useState, useEffect } from "react";
import clsx from "clsx";
import { CtaButtonGroup } from "~/components/ui/button";
import { Container } from "~/components/ui/container";
import { Link } from "~/components/ui/link";
import useScrollSpy from "~/hooks/useScrollSpy";
import type { Cta } from "~/types";
import { Paragraph } from "~/components/ui/typography";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { DEFAULT_SERVICE_ICON_HEIGHT } from "~/utils/media-height";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";
import { Icon } from "~/components/ui/icons";
import { spTrackWebInteraction } from "~/utils/tracking";
import { useDebounce } from "~/utils/hooks";

export type ScrollSpyNavItem = {
	title: string;
	sectionId: string;
};

interface ScrollSpyNavigationProps {
	logoImgProps?: ImageProps;
	logoLink?: Cta;
	navItems: Array<ScrollSpyNavItem>;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	variant?: "horizontal" | "vertical";
}

export function ScrollSpyNavigation({
	navItems,
	primaryCTA,
	secondaryCTA,
	logoImgProps,
	logoLink,
	variant = "horizontal",
}: ScrollSpyNavigationProps) {
	const sectionIds = navItems.map((item) => item.sectionId);

	const activeSectionId = useScrollSpy({
		sectionIds: sectionIds,
		offsetPx: -150,
	});

	const { t } = useSharedContent(Dictionary.BLOG);

	const isVertical = variant === "vertical";

	const [isTocOpen, setIsTocOpen] = useState<boolean>(false);

	const debouncedHandleScroll = useDebounce(() => {
		if (isTocOpen) {
			setIsTocOpen(false);
		}
	}, 200);

	const handleTocClick = () => {
		const newState = !isTocOpen;
		setIsTocOpen(newState);
		spTrackWebInteraction({
			object: "toc",
			action: "toggle",
			value: newState ? "open" : "close",
		});
	};

	useEffect(() => {
		const handleScrollOrClickOutsideToc = (event: Event) => {
			const tocElement = document.getElementById("toc");

			if (event.type === "scroll") {
				debouncedHandleScroll();
			} else if (
				isTocOpen &&
				(!tocElement || !tocElement.contains(event.target as Node)) &&
				!(event.target as HTMLElement)?.closest(
					'#toc-toggle, label[for="toc-toggle"]'
				)
			) {
				setIsTocOpen(false);
			}
		};

		window.addEventListener("scroll", handleScrollOrClickOutsideToc);
		document.addEventListener("mousedown", handleScrollOrClickOutsideToc);

		return () => {
			window.removeEventListener("scroll", handleScrollOrClickOutsideToc);
			document.removeEventListener("mousedown", handleScrollOrClickOutsideToc);
		};
	}, [isTocOpen, debouncedHandleScroll]);

	return (
		<div className="bg-primary">
			<Container
				noPadding
				className={clsx("xxl:px-6 flex items-center gap-6 xl:h-nav", {
					"justify-between": !isVertical,
				})}
			>
				<div className="flex items-center gap-layout1">
					{logoImgProps?.src ? (
						<Image
							loading="eager"
							height={DEFAULT_SERVICE_ICON_HEIGHT}
							width={DEFAULT_SERVICE_ICON_HEIGHT}
							{...logoImgProps}
						/>
					) : null}
					{logoLink?.title ? (
						<Paragraph as="span" size="body-small" fontWeight="font-medium">
							{logoLink.title}
						</Paragraph>
					) : null}
				</div>
				<div
					className={clsx({
						"relative flex-1": isVertical,
					})}
				>
					{isVertical ? (
						<>
							<input
								type="checkbox"
								id="toc-toggle"
								className="hidden"
								checked={isTocOpen}
								onChange={handleTocClick}
							></input>
							<label
								className="text-tagline flex cursor-pointer items-center font-body text-xs uppercase"
								htmlFor="toc-toggle"
							>
								{t("tableOfContents", "Table of contents")}
								<Icon
									name="chevron-down"
									color="arrow"
									className={clsx("ml-3 transition-transform", {
										"-rotate-180": isTocOpen,
									})}
								/>
							</label>
						</>
					) : null}
					<div
						id="toc"
						className={clsx("flex justify-center gap-2", {
							"border-stroke absolute mt-3 hidden flex-col border bg-white px-5 py-3 shadow-md":
								isVertical,
							"!flex": isTocOpen,
						})}
					>
						{navItems.map((item) => {
							const active = item.sectionId === activeSectionId;
							return (
								<Link
									key={item.sectionId}
									className={clsx(
										"py-3",
										{ "hover-underline-expand": !active },
										{
											"underline decoration-theme-primary decoration-1 underline-offset-8":
												active,
										},
										{ "px-3": !isVertical }
									)}
									to={`#${item.sectionId}`}
									trackingText={item.title}
									trackingPosition="scrollspy"
								>
									<Paragraph
										as="span"
										size="body-small"
										fontWeight="font-medium"
										data-label="link-title"
									>
										{item.title}
									</Paragraph>
								</Link>
							);
						})}
					</div>
				</div>
				<div className="flex flex-row-reverse justify-start gap-3">
					<CtaButtonGroup
						primaryCTA={primaryCTA}
						secondaryCTA={secondaryCTA}
						size="dense"
					/>
				</div>
			</Container>
		</div>
	);
}
