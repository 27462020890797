import { Banner as BannerComponent } from "~/components/ui/banner";
import { AdsBanner as AdsBannerComponent } from "~/components/ads-banner";
import { asLink, asText } from "~/sanity/sanity-helpers";
import type { InfoBannerSection as InfoBannerType } from "~/types/sanity-schema";
import { getImageBuilder } from "~/sanity/images";

export function InfoBanner({
	infoBanner,
	variant,
	image,
	darkTheme: isDarkTheme = false,
}: InfoBannerType) {
	const backgroundImage = getImageBuilder(image)
		? `${getImageBuilder(image)?.width(400)}`
		: undefined;

	return (
		<>
			{variant === "vertical" ? (
				<AdsBannerComponent
					title={asText(infoBanner?.title)}
					ctaText={infoBanner?.primaryCTA?.title}
					ctaLink={asLink(infoBanner?.primaryCTA)}
					background={backgroundImage}
					theme={isDarkTheme ? "dark" : "light"}
				>
					{asText(infoBanner?.description)}
				</AdsBannerComponent>
			) : (
				<BannerComponent
					position="default"
					tagline={asText(infoBanner?.tagline)}
					cta={{
						title: infoBanner?.primaryCTA?.title,
						url: asLink(infoBanner?.primaryCTA),
					}}
					id={asText(infoBanner?.slug?.current)}
				>
					{asText(infoBanner?.title)}
				</BannerComponent>
			)}
		</>
	);
}
