import clsx from "clsx";
import { H2, Paragraph } from "~/components/ui/typography";
import type { Cta } from "~/types";
import { BlockContent } from "~/components/block-content";
import { Card } from "./ui/card";
import Carousel from "./carousel/carousel";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import type { SimplePortableText } from "~/types/sanity-schema";
import { Tag } from "~/components/ui/tag";
import Quote from "~/images/shape-quote.svg";
import { Spacer } from "~/components/ui/spacer";
import { Youtube } from "./youtube";
import { ConditionalLink } from "./ui/link";
import { ArrowLink } from "./ui/arrow-button";

const SLIDES_TO_SHOW_MAPPING = {
	media: 1,
	default: 3,
	people: 3,
	logo: 3,
};

type Tags = {
	type: "service" | "industry";
	title: string;
}[];

export type TestimonialItemProps = {
	image?: ImageProps;
	companyLogo?: ImageProps;
	ytId?: string;
	tagline?: string;
	name?: string;
	jobTitle?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	content?: any;
	cta?: Cta;
	logoHeight?: number;
	tags?: Tags;
};

function TagList(tags?: Tags) {
	if (!tags || tags.length === 0) return null;

	return (
		<ul className="flex flex-col flex-wrap gap-3 sm:flex-row sm:items-center">
			{tags
				.sort((a, b) => {
					// if types are the same, just don't sort them
					if (a.type === b.type) return 0;

					// otherwise, sort by alphabetical order as we want to render industry tags first
					return a.type > b.type ? 1 : -1;
				})
				.map((tag, index: number) => {
					return (
						<li key={index}>
							<Tag
								bgColor={
									tag.type === "industry" ? "bg-theme-secondary" : "bg-grey-5"
								}
								textColor={
									tag.type === "industry" ? "theme-secondary" : "primary"
								}
							>
								{tag.title}
							</Tag>
						</li>
					);
				})}
		</ul>
	);
}

function TestimonialItem({
	item,
	variant,
	hideItemLink,
	showCompanyLogoAndTags = false,
	hideMedia = false,
}: {
	item: TestimonialItemProps;
	variant: "default" | "media" | "logo" | "people";
	hideItemLink: boolean;
	showCompanyLogoAndTags?: boolean;
	hideMedia?: boolean;
}) {
	const { tags } = item;
	const ctaUrl = item.cta?.url || "";
	const hasMedia = !hideMedia && (item.image?.src || item.ytId);

	return (
		<article
			className={clsx("flex-1", {
				"h-full": variant === "logo" || variant === "media",
			})}
		>
			{variant === "media" ? (
				<Card
					hasLinkInside={!hideItemLink && Boolean(item.cta?.url)}
					className={clsx({
						"!border-none bg-theme-secondary p-6 text-center":
							!hasMedia && !showCompanyLogoAndTags,
					})}
				>
					<Card.Content>
						<div className="flex flex-col gap-layout4 md:flex-row-reverse md:items-center">
							{hasMedia ? (
								<div className="flex-1">
									{item.ytId ? (
										<Youtube id={item.ytId} />
									) : item.image?.src ? (
										<Image className="mx-auto rounded-lg" {...item.image} />
									) : null}
								</div>
							) : null}
							<div className="flex-1">
								{showCompanyLogoAndTags && item.companyLogo?.src ? (
									<Card.Logo
										imgProps={item.companyLogo}
										className="flex h-[72px] items-center"
										logoHeight={item.logoHeight}
									/>
								) : null}
								<ConditionalLink
									to={!hideItemLink ? ctaUrl : ""}
									condition={!hideItemLink && Boolean(item.cta?.url)}
								>
									{!hasMedia && !showCompanyLogoAndTags ? (
										<Image
											className="mx-auto pb-6"
											width="44px"
											height="33px"
											src={Quote}
											alt=""
										/>
									) : null}
									{item.tagline ? (
										<Paragraph size="overline" className="mb-5" color="tagline">
											{item.tagline}
										</Paragraph>
									) : null}
									{item.content ? (
										<BlockContent
											value={item.content}
											paragraphColor="primary"
											paragraphClassName="lg:first-of-type:!text-lg"
											headingClassName="first-of-type:mt-0"
										/>
									) : null}
									{item.name && item.jobTitle ? (
										<Card.People
											name={item.name}
											jobTitle={item.jobTitle}
											className={clsx({
												"justify-center": !hasMedia && !showCompanyLogoAndTags,
											})}
										/>
									) : null}
									<Spacer size="layout1" />
									{showCompanyLogoAndTags ? TagList(tags) : null}
									{!hideItemLink && item.cta && item.cta.url ? (
										<Card.GhostArrowCTA className="mt-6">
											{item.cta.title}
										</Card.GhostArrowCTA>
									) : null}
								</ConditionalLink>
							</div>
						</div>
					</Card.Content>
				</Card>
			) : (
				<Card href={!hideItemLink && ctaUrl ? ctaUrl : ""}>
					{item.image?.src && variant === "default" ? (
						<Card.Cover imgProps={item.image} />
					) : null}
					<Card.Content className="flex h-full flex-col">
						{variant === "default" &&
						showCompanyLogoAndTags &&
						item.companyLogo?.src ? (
							<Card.Logo
								imgProps={item.companyLogo}
								className="flex h-[72px] items-center"
								logoHeight={item.logoHeight}
							/>
						) : null}
						{item.image?.src && variant === "logo" ? (
							<Card.Logo
								imgProps={item.image}
								className="flex h-[72px] items-center"
								logoHeight={item.logoHeight}
							/>
						) : null}
						{item.content ? (
							<BlockContent
								value={item.content}
								paragraphColor="primary"
								headingClassName="first-of-type:mt-0"
							/>
						) : null}
						{item.image?.src && variant === "people" ? (
							<Card.People
								imgProps={item.image}
								name={item.name}
								jobTitle={item.jobTitle}
								className="mt-auto"
							/>
						) : (
							<Card.People name={item.name} jobTitle={item.jobTitle} />
						)}
					</Card.Content>
					<Card.Extra>
						{showCompanyLogoAndTags ? TagList(tags) : null}
						{!hideItemLink && item.cta && item.cta.url ? (
							<Card.GhostArrowCTA className="mt-6">
								{item.cta.title}
							</Card.GhostArrowCTA>
						) : null}
					</Card.Extra>
				</Card>
			)}
		</article>
	);
}

interface TestimonialListProps {
	tagline?: string;
	title?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	subtitle?: SimplePortableText;
	cta?: Cta;
	items: Array<TestimonialItemProps>;
	variant: "default" | "media" | "logo" | "people";
	isCarousel: boolean;
	isHideItemLink: boolean;
	showCompanyLogoAndTags?: boolean;
	isHideMedia?: boolean;
}

export function TestimonialList({
	tagline,
	title,
	subtitle,
	cta,
	items,
	variant = "media",
	isCarousel,
	isHideItemLink,
	showCompanyLogoAndTags = false,
	isHideMedia = false,
}: TestimonialListProps) {
	return (
		<>
			{tagline || title || subtitle ? (
				<div className="mx-auto mb-7 max-w-content md:text-center">
					{tagline ? (
						<Paragraph size="overline" className="mb-5" color="tagline">
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2 className="mb-5">{title}</H2> : null}
					{subtitle ? <BlockContent value={subtitle} /> : null}
					{cta && cta.url ? (
						<ArrowLink to={cta.url}>{cta.title}</ArrowLink>
					) : null}
				</div>
			) : null}
			{items.length > 0 ? (
				isCarousel ? (
					<Carousel
						adaptiveHeight={true}
						slidesToShow={
							SLIDES_TO_SHOW_MAPPING[variant] > items.length
								? items.length
								: SLIDES_TO_SHOW_MAPPING[variant]
						}
					>
						{items.map((item, index) => (
							<div key={`${item.name} ${index}`}>
								<div className="h-full px-4">
									<TestimonialItem
										variant={variant}
										item={item}
										hideItemLink={isHideItemLink}
										showCompanyLogoAndTags={showCompanyLogoAndTags}
									/>
								</div>
							</div>
						))}
					</Carousel>
				) : (
					<div
						className={clsx(
							{
								"md:grid md:grid-cols-2":
									(items.length === 2 || items.length === 4) &&
									variant !== "media",
							},
							{
								"md:grid md:grid-cols-3":
									items.length >= 3 &&
									items.length !== 4 &&
									variant !== "media",
							},
							{ "md:flex-col": variant === "media" },
							{ "md:flex-row": variant !== "media" },
							"flex flex-col justify-center gap-7"
						)}
					>
						{items.map((item, index) => (
							<TestimonialItem
								key={`${item.name} ${index}`}
								variant={variant}
								item={item}
								hideItemLink={isHideItemLink}
								showCompanyLogoAndTags={showCompanyLogoAndTags}
								hideMedia={isHideMedia}
							/>
						))}
					</div>
				)
			) : null}
		</>
	);
}
