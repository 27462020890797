import { ArcadeEmbedded, Header } from "../arcade";
import type { Arcade as ArcadeType } from "~/types/sanity-schema";
import { asText } from "~/sanity/sanity-helpers";
import clsx from "clsx";

export function Arcade({
	variant,
	tagline,
	title,
	subtitle,
	arcadeTitle,
	arcadeID,
	arcadeHeight,
}: ArcadeType) {
	return (
		<div
			className={clsx("flex flex-col gap-layout4", {
				"xl:flex-row": variant === "split",
			})}
		>
			<Header
				className={clsx({
					"mx-auto max-w-content text-center": variant === "default",
					"basis-2/6 xl:min-h-[450px]": variant === "split",
				})}
				tagline={tagline}
				title={title}
				subtitle={subtitle}
			/>
			<ArcadeEmbedded
				className={clsx({
					"basis-4/6 xl:!pb-0": variant === "split",
				})}
				title={asText(arcadeTitle)}
				id={asText(arcadeID)}
				minHeight={arcadeHeight}
			/>
		</div>
	);
}
