import { Hero as HeroComponent } from "~/components/hero/hero";
import { Tag } from "~/components/ui/tag";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/sanity/images";
import { asLink, asText } from "~/sanity/sanity-helpers";
import type { AdditionalSectionData } from "~/components/renderSections";
import type { Logo } from "~/components/logo-list";
import type {
	Hero as HeroType,
	HostProviderDocument,
	SanityReference,
	CaptionImage,
	TitleImage,
} from "~/types/sanity-schema";
import { useMobile } from "~/utils/hooks";
import { useSharedContent } from "~/hooks/localization";
import { BreadCrumbs, Crumb } from "~/components/ui/breadcrumbs";
import { Icon } from "~/components/ui/icons";

function isHostProviderItems(
	hostProviders: HostProviderDocument[] | SanityReference[] | undefined
): hostProviders is Array<HostProviderDocument> {
	return (
		Array.isArray(hostProviders) &&
		hostProviders[0] &&
		"logo" in hostProviders[0]
	);
}

function isCarouselItems(
	carouselItems: CaptionImage[] | undefined
): carouselItems is Array<CaptionImage> {
	return (
		Array.isArray(carouselItems) &&
		carouselItems[0] &&
		"image" in carouselItems[0]
	);
}

function isTitleImageSyncSlides(
	carouselSyncTitleItems: TitleImage[] | undefined
): carouselSyncTitleItems is Array<TitleImage> {
	return (
		Array.isArray(carouselSyncTitleItems) &&
		carouselSyncTitleItems[0] &&
		"image" in carouselSyncTitleItems[0] &&
		"title" in carouselSyncTitleItems[0]
	);
}

type Props = HeroType & {
	supportedVersion: AdditionalSectionData["supportedVersion"];
	externalId: AdditionalSectionData["externalId"];
};

export function Hero({
	darkTheme: isDarkTheme = false,
	titleMultiColor: isTitleMultiColor = false,
	tagline,
	title,
	headline,
	primaryCTA,
	secondaryCTA,
	image,
	description,
	variant = "default",
	backgroundColor,
	backgroundImage,
	backgroundPosition,
	backgroundSize,
	backgroundImageMobile,
	backgroundPositionMobile,
	backgroundSizeMobile,
	backgroundVideo,
	hostProviders,
	hideHostProviderTitle,
	imageSize,
	videoHosting,
	wistiaId,
	ytId,
	heroHasVideo,
	supportedVersion,
	externalId,
	roundedCorners,
	breadcrumbs,
	carouselItems,
	carouselSyncTitleItems,
}: Props) {
	const isMobile = useMobile();
	const { t } = useSharedContent();

	const heroImage = getImageProps(
		getImageBuilder(image, { alt: getImageAltProp(image) }),
		{
			widths: [400, 500, 600, 700, 800, 900],
			sizes: ["(min-width: 1024px) 600px, 50vw"],
		}
	);
	const backgroundImageSource = !isMobile
		? backgroundImage
		: backgroundImageMobile;
	const heroBackgroundImage = getImageBuilder(backgroundImageSource)
		? `url(${getImageBuilder(backgroundImageSource)})`
		: undefined;
	const heroBackgroundImagePosition = !isMobile
		? backgroundPosition
		: backgroundPositionMobile;
	const heroBackgroundSize = !isMobile ? backgroundSize : backgroundSizeMobile;
	const backgroundColorCss = backgroundColor;

	const heroRoundedCorners =
		roundedCorners === "rounded-none"
			? "rounded-none"
			: "rounded-b-2xl lg:rounded-b-6xl";

	const hostProvidersSizes: Record<string, number> = {
		UpCloud: 20,
		"Digital Ocean": 40,
		"Microsoft Azure": 24,
		"Google Cloud": 22,
		"Amazon Web Services": 28,
		"Oracle Cloud": 26,
		"Digital Ocean (Square)": 40,
		"UpCloud (Square)": 39,
		"BYOC (Square)": 39,
	};
	const hostProviderItems: Logo[] = isHostProviderItems(hostProviders)
		? hostProviders.map((item) => ({
				logoImage: getImageProps(
					getImageBuilder(
						isDarkTheme ? item?.logo?.logoLight : item?.logo?.logoDark,
						{
							alt: isDarkTheme
								? item?.logo?.logoLight?.alt
								: item?.logo?.logoDark?.alt,
						}
					)?.height(48)
				),
				logoHeight: item?.name ? hostProvidersSizes[item?.name] : 24,
			}))
		: [];

	const carouselImages: CaptionImage[] = isCarouselItems(carouselItems)
		? carouselItems.map((item) => ({
				_type: "captionImage",
				image: getImageProps(
					getImageBuilder(item?.image, {
						alt: getImageAltProp(item?.image, {
							fallback: `${item?.caption}`,
						}),
					}),
					{
						widths: [400, 500, 600, 700, 800, 900],
						sizes: ["(min-width: 1024px) 450px, 50vw"],
					}
				),
				caption: item?.caption,
			}))
		: [];

	const carouselSyncTitleSlides: TitleImage[] = isTitleImageSyncSlides(
		carouselSyncTitleItems
	)
		? carouselSyncTitleItems.map((item) => ({
				_type: "titleImage",
				image: getImageProps(
					getImageBuilder(item?.image, {
						alt: getImageAltProp(item?.image, {
							fallback: `${item?.title}`,
						}),
					}),
					{
						widths: [400, 500, 600, 700, 800, 900],
						sizes: ["(min-width: 1024px) 450px, 50vw"],
					}
				),
				title: item?.title,
			}))
		: [];

	function getTagLine() {
		const isFreePlanAvailable =
			externalId === "mysql" || externalId === "valkey" || externalId === "pg";

		const isEarlyAvailability = externalId === "alloydbomni";

		if (supportedVersion) {
			return (
				<div className="mb-5 flex flex-wrap items-center justify-center gap-3 lg:justify-start">
					<Tag>{`${t("nowRunning")} ${supportedVersion}`}</Tag>
					{isFreePlanAvailable ? (
						<Tag bgColor="bg-green-0" textColor="green">
							{t("freePlanAvailable", "Free plan available")}
						</Tag>
					) : isEarlyAvailability ? (
						<Tag bgColor="bg-blue-0" textColor="blue">
							{t("earlyAvailability", "Early availability")}
						</Tag>
					) : null}
				</div>
			);
		}

		return asText(tagline);
	}

	function getBreadcrumbs() {
		if (breadcrumbs?.parentPage && breadcrumbs.currentPage) {
			return (
				<BreadCrumbs>
					<Crumb to={asLink(breadcrumbs.parentPage) || ""}>
						{breadcrumbs.parentPage.title}
					</Crumb>
					<Crumb isCurrentPage={true}>{breadcrumbs.currentPage}</Crumb>
				</BreadCrumbs>
			);
		} else {
			return undefined;
		}
	}
	return (
		<HeroComponent
			isDarkTheme={isDarkTheme}
			isTitleMultiColor={isTitleMultiColor}
			variant={variant}
			tagline={getTagLine()}
			image={heroImage}
			breadcrumb={getBreadcrumbs()}
			title={!isTitleMultiColor ? asText(title) : ""}
			description={!isTitleMultiColor ? description : headline}
			primaryCTA={{
				title: primaryCTA?.title,
				caption: primaryCTA?.caption,
				url: asLink(primaryCTA),
				trackingPosition: "hero",
				iconLeft: primaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			secondaryCTA={{
				title: secondaryCTA?.title,
				caption: secondaryCTA?.caption,
				url: asLink(secondaryCTA),
				trackingPosition: "hero",
				iconLeft: secondaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			backgroundColor={backgroundColorCss}
			backgroundImage={heroBackgroundImage}
			backgroundPosition={heroBackgroundImagePosition}
			backgroundSize={heroBackgroundSize}
			backgroundVideo={backgroundVideo}
			hostProviders={hostProviderItems}
			hideHostProviderTitle={hideHostProviderTitle}
			imageSize={imageSize}
			videoHosting={videoHosting}
			wistiaId={wistiaId}
			ytId={ytId}
			heroHasVideo={heroHasVideo}
			roundedCorners={heroRoundedCorners}
			carouselItems={carouselImages}
			carouselSyncTitleItems={carouselSyncTitleSlides}
		/>
	);
}
