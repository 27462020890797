import type { MarkdownSection } from "~/types/sanity-schema";
import { MarkDown } from "../markdown";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";

export function MarkdownSection({ content }: MarkdownSection) {
	const { t } = useSharedContent(Dictionary.BLOG);
	return (
		<>
			<MarkDown
				content={content}
				withToc={true}
				tocTitle={t("tableOfContents", "Table of contents")}
				tocTop="calc(var(--nav-height) + var(--nav-height-secondary) + 32px)"
			/>
		</>
	);
}
