import { TextColumn as TextColumnComponent } from "../text-column";
import { asLink, asText } from "~/sanity/sanity-helpers";
import type { TextColumn as TextColumnType } from "~/types/sanity-schema";

export function TextColumn({
	variant = "oneColumn",
	tagline,
	title,
	subtitle,
	columnOne,
	columnTwo,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
}: TextColumnType) {
	return (
		<TextColumnComponent
			variant={variant}
			tagline={tagline}
			title={asText(title)}
			subtitle={subtitle}
			columnOne={columnOne}
			columnTwo={columnTwo}
			primaryCTA={{
				title: primaryCTA?.title,
				caption: primaryCTA?.caption,
				url: asLink(primaryCTA),
				trackingPosition: "text column",
			}}
			secondaryCTA={{
				title: secondaryCTA?.title,
				caption: secondaryCTA?.caption,
				url: asLink(secondaryCTA),
				trackingPosition: "text column",
			}}
			tertiaryCTA={{
				title: tertiaryCTA?.title,
				caption: tertiaryCTA?.caption,
				url: asLink(tertiaryCTA),
				trackingPosition: "text column",
			}}
		/>
	);
}
