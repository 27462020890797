import { useState } from "react";
import { H4, Paragraph } from "~/components/ui/typography";
import { Divider } from "~/components/ui/divider";
import { Icon } from "~/components/ui/icons";
import { ButtonLink, Button } from "~/components/ui/button";
import type { Addon, GroupedPlansData, PlanFeature } from "~/types/product";
import { Checkbox } from "~/components/ui/form/checkbox";
import clsx from "clsx";
import {
	getAddonNameForType,
	formatCurrency,
	getMinMaxValue,
} from "~/utils/pricing";
import { Modal } from "~/components/ui/modal";
import { PricingTable } from "./pricing-table";
import { Skeleton } from "~/components/ui/skeleton";
import { supplant } from "~/utils/misc";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";
import { MarkDown } from "~/components/markdown";
interface Props {
	highLighted?: boolean;
	planGroup: GroupedPlansData["group"];
	className?: string;
	addons: Addon[];
	signupUrl: string;
	planFeature: PlanFeature["plans"][number] | undefined;
	onAddOnSelect?: (planGroupName: string, type: string) => void;
	service: string;
}

export function PlanCardSkeleton({ highLighted }: { highLighted: boolean }) {
	const { t } = useSharedContent(Dictionary.PRICING);

	return (
		<div
			className={clsx(
				"flex h-full flex-col rounded border p-6",
				{ "border-stroke": !highLighted },
				{ "border-grey-80": highLighted }
			)}
		>
			<Skeleton className="mb-3 h-[28px]" />
			<Skeleton className="mb-2 h-[21px]" />
			<Skeleton className="mb-2 h-[33px]" />
			<Skeleton className="mb-2 h-[21px]" />
			<Divider size="layout2" className="w-full" />
			<div className="flex-1">
				<Skeleton className="mb-3 h-[18px]" />
				<ul className="mb-6 flex flex-col gap-3">
					{new Array(4).fill(0).map((_, index) => (
						<li key={index} className="flex items-baseline gap-3">
							<Icon className="shrink-0" name="check" color="success" />
							<Skeleton className="mb-2 h-[21px] w-full" />
						</li>
					))}
				</ul>
			</div>
			<div className="flex flex-col gap-3">
				<ButtonLink variant={highLighted ? "primary" : "secondary"} to="/">
					{t("ctaSignup", "Get started for free")}
				</ButtonLink>
				<div className="block h-[44px]"></div>
			</div>
		</div>
	);
}

function AddOns({
	addons,
	planGroup,
	selectedAddOnTypes,
	handleAddonSelect,
	t,
}: {
	addons: Addon[];
	planGroup: GroupedPlansData["group"];
	selectedAddOnTypes: Array<string>;
	handleAddonSelect: (planGroupName: string, type: string) => void;
	t: (key: string, fallbackText?: string) => string;
}) {
	const excludedEnterpriseAddons = [
		"aws-tgw-vpc-attachment",
		"pl-vpc-attachment",
	];

	return (
		<>
			<Paragraph size="overline" className="mb-3">
				{t("addons", "Add-ons")}
			</Paragraph>
			{addons
				.filter(({ type }) => !excludedEnterpriseAddons.includes(type))
				.map(({ type, pricePerHourUsd = 0, pricePerGB = 0 }) => {
					let tooltipKey = "";
					let price = 0;

					switch (type) {
						case "aws-tgw-vpc-attachment":
							tooltipKey = "awsTgwTooltip";
							price = pricePerHourUsd;
							break;
						case "sip-vpc-attachment":
							tooltipKey = "staticIpTooltip";
							price = pricePerHourUsd;
							break;
						case "pl-vpc-attachment":
							tooltipKey = "privateLinkTooltip";
							price = pricePerGB;
							break;
						case "tiered-storage":
							tooltipKey = "kafkaTieredStorageTooltip";
							price = pricePerGB;
							break;
						default:
							break;
					}

					return (
						<div key={type} className="mb-5">
							<Checkbox
								id={`option-${planGroup.name}-${type}`}
								value={type}
								checked={selectedAddOnTypes.includes(type)}
								onChange={() => handleAddonSelect(planGroup.name, type)}
							>
								{getAddonNameForType(type)}
							</Checkbox>
							{selectedAddOnTypes.includes(type) && price ? (
								<Paragraph
									className="ml-6 mt-3"
									size="body-small"
									color="secondary"
									aria-labelledby={`option-${planGroup.name}-${type}`}
								>
									{supplant(t(tooltipKey), { price })}
								</Paragraph>
							) : null}
						</div>
					);
				})}
		</>
	);
}

function Features({
	planFeature,
	planGroup,
}: {
	planFeature: PlanFeature["plans"][number];
	planGroup: GroupedPlansData["group"];
}) {
	return (
		<ul className="mb-6 flex flex-col gap-3">
			{planFeature.features.map((feature, index) => (
				<li key={index} className="flex items-baseline gap-3">
					<Icon className="shrink-0" name="check" color="success" />
					<span>
						{supplant(feature, {
							nodeCount: getMinMaxValue(
								planGroup.minNodeCount,
								planGroup.maxNodeCount
							),
							cpuCount: getMinMaxValue(
								planGroup.minCpuCount,
								planGroup.maxCpuCount
							),
							memoryGb: getMinMaxValue(
								planGroup.minMemoryGb,
								planGroup.maxMemoryGb
							),
							shardCount: getMinMaxValue(
								planGroup.minShardCount,
								planGroup.maxShardCount
							),
							diskSpaceGb: getMinMaxValue(
								planGroup.minDiskSpaceGb,
								planGroup.maxDiskSpaceGb
							),
						})}
					</span>
				</li>
			))}
		</ul>
	);
}

export function PlanCard({
	planGroup,
	className,
	highLighted = false,
	addons,
	signupUrl,
	planFeature,
	onAddOnSelect,
	service,
}: Props) {
	const [selectedAddOnTypes, setSelectedAddOnTypes] = useState<Array<string>>(
		[]
	);
	const [showPricingModal, setShowPricingModal] = useState(false);

	const isFreePlan = planGroup.name == "Free";

	const closeModal = () => setShowPricingModal(false);

	const openModal = () => setShowPricingModal(true);

	const { t } = useSharedContent(Dictionary.PRICING);

	function handleAddonSelect(planGroupName: string, type: string) {
		if (onAddOnSelect) {
			onAddOnSelect(planGroupName, type);
		}

		setSelectedAddOnTypes((prevAddOnTypes) => {
			let updatedAddOnTypes;
			if (prevAddOnTypes.includes(type)) {
				updatedAddOnTypes = prevAddOnTypes.filter((addon) => addon !== type);
			} else {
				updatedAddOnTypes = [...prevAddOnTypes, type];
			}

			return updatedAddOnTypes;
		});
	}

	return (
		<>
			<div
				className={clsx(
					"bg-primary flex h-full flex-col rounded border p-6",
					{ "border-stroke": !highLighted },
					{ "border-grey-80": highLighted },
					className
				)}
			>
				<div className="md:min-h-[109px]">
					<Paragraph
						className="!text-orange-60"
						size="body-large"
						as="h3"
						fontWeight="font-semibold"
					>
						{planGroup.name}
					</Paragraph>
					{!isFreePlan ? (
						<Paragraph size="body-small" as="span">
							{t("startingFrom", "starting from")}
						</Paragraph>
					) : null}
					<H4 as="p">
						{formatCurrency({
							value: planGroup.minPricePerMonthUsd,
							fractionDigits: 0,
						})}
						/{t("month", "month")}
					</H4>
					{!isFreePlan ? (
						<Paragraph size="body-small" as="span">
							{formatCurrency({
								value: planGroup.minPricePerHourUsd,
							})}
							/{t("hour", "hour")}
						</Paragraph>
					) : null}
				</div>
				<Divider size="layout2" className="w-full" />
				<div className="flex-1">
					{planFeature ? Features({ planFeature, planGroup }) : null}
					{planGroup.name != "Free" && addons.length > 0
						? AddOns({
								addons,
								selectedAddOnTypes,
								handleAddonSelect,
								planGroup,
								t,
							})
						: null}
				</div>
				<div className="flex flex-col gap-3">
					<ButtonLink
						prefetch="none"
						variant={highLighted ? "primary" : "secondary"}
						to={signupUrl}
					>
						{t("ctaSignup", "Get started for free")}
					</ButtonLink>
					{planGroup.plans.length > 1 ? (
						<Button variant="ghost" onClick={openModal}>
							{t("ctaPriceTier", "View price tier")}
						</Button>
					) : (
						<div className="block h-[44px]"></div>
					)}
				</div>
			</div>
			<Modal isOpen={showPricingModal} onClose={closeModal} size="lg">
				<Modal.Header
					title={`${planGroup.name} plans`}
					subtitle={<MarkDown content={t("planDisclaimer")} fullWidth={true} />}
				/>
				<Modal.Content>
					<PricingTable planGroup={planGroup} service={service} />
				</Modal.Content>
			</Modal>
		</>
	);
}
