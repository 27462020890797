import clsx from "clsx";
import type {
	PlanComparison as PlanComparisonType,
	GroupedPlansData,
	ProductSignupParams,
} from "~/types/product";
import { Icon } from "~/components/ui/icons";
import { Table } from "~/components/ui/table";
import { Image } from "~/components/ui/image";
import type { ImageProps } from "~/components/ui/image";
import { H4, Paragraph } from "~/components/ui/typography";
import { ButtonLink } from "../ui/button";
import { useMobile } from "~/utils/hooks";
import { getSignupUrl, getMinMaxValue, formatCurrency } from "~/utils/pricing";
import { supplant } from "~/utils/misc";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";

type Props = {
	data: PlanComparisonType;
	product: {
		title: string;
		iconProps: ImageProps;
	};
	pricingData: GroupedPlansData;
	loading?: boolean;
	signupParams: ProductSignupParams;
};

export function PlanComparison({
	data,
	product,
	pricingData,
	loading = false,
	signupParams,
}: Props) {
	// filter out column that doesn't have any data
	const headingLabels = data.rows[0].cols
		.map((col) => col.label)
		.filter((label) => pricingData[label]);

	const borderRight = "border-r last:border-r-0 !border-stroke";

	const isMobile = useMobile();

	const { t } = useSharedContent(Dictionary.PRICING);

	return (
		<div
			className={clsx({
				"overflow-x-auto": isMobile,
			})}
		>
			<Table
				isLoading={loading}
				className="border-separate border-spacing-0"
				aria-label="Price comparison between plans"
			>
				<Table.Head
					sticky={!isMobile}
					top={!isMobile ? `calc(var(--nav-height) - 1px)` : ""}
					// eslint-disable-next-line tailwindcss/no-custom-classname
					className="!border-stroke"
				>
					<Table.Cell
						className={clsx(
							"bg-primary left-0 w-[200px] border-y md:w-[300px]",
							borderRight,
							{
								"sticky left-0 md:bg-transparent": isMobile,
							}
						)}
					>
						<div className="flex items-center gap-3">
							<Image
								loading="eager"
								className="mb-3 size-7 rounded-full"
								{...product.iconProps}
							/>
							<Paragraph size="body-small">{product.title}</Paragraph>
						</div>
					</Table.Cell>
					{headingLabels.map((label, index) => {
						return (
							<Table.Cell
								key={index}
								className={clsx("border-y align-top", borderRight)}
							>
								<Paragraph fontWeight="font-semibold" className="mb-2">
									{label}
								</Paragraph>
								{label != "Free" ? (
									<Paragraph size="body-small" className="mb-2">
										{t("startingFrom", "starting from")}
									</Paragraph>
								) : null}
								{pricingData[label] ? (
									<H4 as="p">
										{formatCurrency({
											value: pricingData[label].minPricePerMonthUsd,
											fractionDigits: 0,
										})}
										/{t("month", "month")}
									</H4>
								) : null}
							</Table.Cell>
						);
					})}
				</Table.Head>
				<Table.Body>
					{data.rows.map((row) => {
						return (
							<Table.Row key={row.label}>
								<Table.Cell
									className={clsx(
										"bg-primary !p-5 md:bg-transparent",
										borderRight,
										{
											"sticky left-0 md:bg-transparent": isMobile,
										}
									)}
								>
									<Paragraph size="body-small" fontWeight="font-semibold">
										{row.label}
									</Paragraph>
									{row.description ? (
										<Paragraph size="body-small" className="mt-2">
											{row.description}
										</Paragraph>
									) : null}
								</Table.Cell>
								{row.cols.map((col) => {
									const colData = pricingData[col.label];

									return colData ? (
										<Table.Cell
											className={clsx("!p-5 !align-middle", borderRight)}
											key={col.label}
										>
											{col.value.checked || col.value.text ? (
												<div className="flex items-baseline gap-3">
													{col.value.checked ? (
														<Icon
															name="check"
															color="success"
															ariaLabel="Available"
														/>
													) : null}
													{col.value.text ? (
														<Paragraph size="body-small">
															{supplant(col.value.text, {
																nodeCount: getMinMaxValue(
																	colData.minNodeCount,
																	colData.maxNodeCount
																),
																shardCount: getMinMaxValue(
																	colData.minShardCount,
																	colData.maxShardCount
																),
																diskSpaceGb: getMinMaxValue(
																	colData.minDiskSpaceGb,
																	colData.maxDiskSpaceGb
																),
																cpuCount: getMinMaxValue(
																	colData.minCpuCount,
																	colData.maxCpuCount
																),
																memoryGb: getMinMaxValue(
																	colData.minMemoryGb,
																	colData.maxMemoryGb
																),
															})}
														</Paragraph>
													) : null}
												</div>
											) : (
												<Icon
													name="dash"
													className="!text-grey-30"
													color="current"
													ariaLabel="Not available"
												/>
											)}
										</Table.Cell>
									) : null;
								})}
							</Table.Row>
						);
					})}
					<Table.Row>
						<Table.Cell
							className={clsx("bg-primary !p-5", borderRight, {
								"sticky left-0 md:bg-transparent": isMobile,
							})}
						/>
						{headingLabels.map((label, index) => {
							const highLighted = label === "Business";

							const signUpUrl = getSignupUrl({
								...signupParams,
								plan: label,
							});

							return (
								<Table.Cell className={borderRight} key={index}>
									<ButtonLink
										className="w-full min-w-[180px]"
										variant={highLighted ? "primary" : "secondary"}
										to={signUpUrl}
									>
										{t("ctaSignup", "Get started for free")}
									</ButtonLink>
								</Table.Cell>
							);
						})}
					</Table.Row>
				</Table.Body>
			</Table>
		</div>
	);
}
