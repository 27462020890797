import { LogoList as LogoListComponent } from "../logo-list";
import type { Logo } from "../logo-list";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/sanity/images";
import { asLink, asText } from "~/sanity/sanity-helpers";
import type {
	CompanyDocument,
	LogoList as LogoListType,
	SanityReference,
} from "~/types/sanity-schema";
import { DEFAULT_LOGO_HEIGHT } from "~/utils/media-height";

function isLogoItems(
	logos: CompanyDocument[] | SanityReference[] | undefined
): logos is Array<SanityReference> {
	return Array.isArray(logos) && logos[0] && "companyLogo" in logos[0];
}

export function LogoList({
	align,
	title,
	subtitle,
	tagline,
	grayscale: isGrayscale,
	showLogoCaption: isShowLogoCaption,
	hideItemLink: isHideItemLink,
	logos,
	variant,
	primaryCTA,
}: LogoListType) {
	const logoItems: Logo[] = isLogoItems(logos)
		? // eslint-disable-next-line @typescript-eslint/no-explicit-any
			logos.map((item: any) => ({
				title: item?.companyName,
				logoImage: getImageProps(
					getImageBuilder(item?.companyLogo, {
						alt: getImageAltProp(item?.companyLogo, {
							fallback: `${item?.companyName} logo`,
						}),
					})?.height(item?.logoHeight ? item?.logoHeight : DEFAULT_LOGO_HEIGHT)
				),
				logoHeight: item?.logoHeight,
				ctaLink: item?.companyWebsite,
			}))
		: [];

	return (
		<LogoListComponent
			align={align}
			variant={variant}
			logoItems={logoItems}
			isGrayscale={isGrayscale}
			isShowLogoCaption={isShowLogoCaption}
			isHideItemLink={isHideItemLink}
			title={asText(title)}
			subtitle={subtitle}
			tagline={asText(tagline)}
			primaryCTA={{
				title: primaryCTA?.title,
				url: asLink(primaryCTA),
			}}
		/>
	);
}
