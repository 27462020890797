import { AvatarList as AvatarListComponent } from "../avatar-list";
import type { Avatar } from "../avatar-list";
import { asText } from "~/sanity/sanity-helpers";
import type { AvatarList as AvatarListType } from "~/types/sanity-schema";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/sanity/images";
import { useSharedContent } from "~/hooks/localization";
import { baseSlug } from "~/utils/base-slug";

export function AvatarList({
	align,
	variant,
	tagline,
	title,
	subtitle,
	avatars,
	hideSoMe,
	showProfilePage,
}: AvatarListType) {
	const { t } = useSharedContent();

	const avatarItems = avatars
		? avatars.map<Avatar>((item) => ({
				image: getImageProps(
					getImageBuilder(item?.image, {
						alt: getImageAltProp(item?.image, {
							fallback: `${item?.name} portrait`,
						}),
					})
						?.width(220)
						.height(220)
				),
				name: asText(item?.name),
				jobTitle: asText(item?.jobTitle),
				bio: item?.bio,
				hideSoMe: Boolean(hideSoMe),
				showProfilePage: Boolean(showProfilePage),
				socialLink: {
					facebook: item?.socialLink?.facebook,
					twitter: item?.socialLink?.twitter,
					linkedIn: item?.socialLink?.linkedIn,
					github: item?.socialLink?.github,
					mastodon: item?.socialLink?.mastodon,
					email: item?.socialLink?.email,
				},
				cta: {
					title: t("learnMore"),
					url:
						item.role === "dev-rel"
							? `${baseSlug.devRel}${item.slug.current}`
							: item.role === "community-advocates"
								? `${baseSlug.communityAdvocates}${item.slug.current}`
								: undefined,
				},
			}))
		: [];

	return (
		<AvatarListComponent
			align={align}
			variant={variant}
			tagline={tagline}
			title={title}
			subtitle={subtitle}
			avatarItems={avatarItems}
		/>
	);
}
