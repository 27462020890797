import type {
	AvatarList as AvatarListType,
	EventSessionList as EventSessionListType,
	EventSessionItem,
} from "~/types/sanity-schema";
import { TextColumn as TextColumnComponent } from "../text-column";
import { Paragraph } from "../ui/typography";
import { asText } from "~/sanity/sanity-helpers";
import { Tag } from "../ui/tag";
import { BlockContent } from "../block-content";
import type { Avatar } from "../avatar-list";
import { AvatarItem as AvatarItemComponent } from "../avatar-list";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/sanity/images";
import clsx from "clsx";

function AvatarList({ avatars }: AvatarListType) {
	const avatarItems = avatars
		? avatars.map<Avatar>((item) => ({
				image: getImageProps(
					getImageBuilder(item?.image, {
						alt: getImageAltProp(item?.image, {
							fallback: `${item?.name} portrait`,
						}),
					})
						?.width(48)
						.height(48)
				),
				name: asText(item?.name),
				jobTitle: asText(item?.jobTitle),
			}))
		: [];

	return (
		<>
			{avatarItems.map((avatar, index) => (
				<AvatarItemComponent
					key={index}
					variant="dense"
					image={avatar.image}
					name={avatar.name}
					jobTitle={avatar.jobTitle}
				/>
			))}
		</>
	);
}

function EventSession({
	items,
	itemsPerRow,
}: {
	items: Array<EventSessionItem>;
	itemsPerRow: number;
}) {
	return (
		<ul
			className={clsx(
				{
					"lg:mx-auto lg:max-w-content": items.length === 1,
				},
				{
					"grid grid-cols-1 gap-6 lg:grid-cols-2":
						itemsPerRow === 2 && items.length > 1,
				},
				{
					"grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3":
						itemsPerRow === 3 && items.length > 2,
				}
			)}
		>
			{items.map((item, index) => (
				<li key={index} className="bg-primary rounded-2xl p-7">
					<div
						className={clsx("mb-6 flex flex-col gap-3", {
							"lg:flex-row": itemsPerRow === 2,
						})}
					>
						{item.time ? (
							<Tag bgColor="bg-blue-0" textColor="blue">
								<span suppressHydrationWarning>{item.time}</span>
							</Tag>
						) : null}
						{item.category ? <Tag>{item.category}</Tag> : null}
					</div>
					<Paragraph className="mb-3" fontWeight="font-semibold">
						{item.title}
					</Paragraph>
					<BlockContent value={item.description} />
					{item.avatars ? (
						<div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6 pt-4">
							<AvatarList avatars={item.avatars} _type="avatarList" />
						</div>
					) : null}
				</li>
			))}
		</ul>
	);
}

export function EventSessionList({
	tagline,
	title,
	subtitle,
	itemsPerRow,
	items,
}: EventSessionListType) {
	return (
		<>
			<div className="flex flex-col gap-6">
				<div>
					<TextColumnComponent
						variant="centerColumn"
						tagline={tagline}
						title={asText(title)}
						subtitle={subtitle}
					/>
				</div>
				<EventSession
					items={items ?? []}
					itemsPerRow={itemsPerRow ? itemsPerRow : 2}
				/>
			</div>
		</>
	);
}
