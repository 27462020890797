import { useSpinDelay } from "spin-delay";
import { ClientOnly } from "remix-utils/client-only";
import clsx from "clsx";
import type { Dispatch, SetStateAction } from "react";
import React from "react";
import { H3, Paragraph } from "~/components/ui/typography";
import { useNavigation, useSearchParams } from "react-router";
import { useUpdateQueryStringValueWithoutNavigation } from "~/utils/hooks";
import {
	getPricingDefaultFilters,
	getPricingData,
	getPricingRegionForProvider,
	getPricingTableData,
	getSignupUrl,
	getPricingAddonForProviderAndRegion,
} from "~/utils/pricing";
import type {
	PricingFilter,
	PricingRelatedData,
	ProductSignupParams,
	PricingProvider,
	Addon,
	PlanData,
	PlanComparison as PlanComparisonType,
	GroupedPlansData,
} from "~/types/product";
import { Select, Option, OptGroup } from "../ui/form/select";
import { Label } from "../ui/form/label";
import { Spacer } from "../ui/spacer";
import { groupBy } from "lodash-es";
import { Icon } from "../ui/icons";
import { PlanCard, PlanCardSkeleton } from "./plan-card";
import { PlanComparison } from "./plan-comparison";
import { useLocale, useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";
import { MarkDown } from "~/components/markdown";
import { Alert } from "~/components/alert";
import { spTrackPricing, spTrackWebInteraction } from "~/utils/tracking";
import { type Pricing as PricingSection } from "~/types/sanity-schema";
import { asText } from "~/sanity/sanity-helpers";
import { getImageBuilder, getImageProps } from "~/sanity/images";
import {
	ServiceSelect,
	ServiceSelectMobile,
	type ProductItemType,
} from "~/components/service-select";
import { supplant } from "~/utils/misc";

// We will lazy load this component as its only used by our sell team
const PricingTable = React.lazy(() => import("./all-pricing-table"));

interface Props {
	pricingRelatedData: PricingRelatedData;
	variant: PricingSection["variant"];
	defaultCloud: PricingSection["defaultCloud"];
}

export function usePricingFilter(
	pricingRelatedData: PricingRelatedData,
	defaultCloud: PricingSection["defaultCloud"]
) {
	const pricingDefaultFilters = getPricingDefaultFilters({
		cloud: defaultCloud,
	});

	const { pricingData, initialProductId } = pricingRelatedData;

	// Determine visible data based on selected filters
	const {
		data: dataByProvider,
		providers,
		initialProvider,
		initialRegion,
		initialAddon,
	} = getPricingData(pricingData?.plans || [], pricingDefaultFilters);

	const [filters, setFilters] = React.useState<PricingFilter>({
		productId: initialProductId,
		provider: initialProvider,
		region: initialRegion,
		addon: initialAddon,
	});

	// when switch product if provider is not available, use the initial provider
	const selectedPlans =
		dataByProvider[filters.provider]?.[filters.region]?.plans ||
		dataByProvider[initialProvider]?.[initialRegion]?.plans;
	const providerRegions = Object.values(
		dataByProvider[filters.provider] || dataByProvider[initialProvider]
	);

	const providerRegionsGroupedByLocation = groupBy(
		providerRegions,
		({ location }) => location.split(",")[0]
	);

	const addons =
		dataByProvider[filters.provider]?.[filters.region]?.addons ||
		dataByProvider[initialProvider]?.[initialRegion]?.addons;

	function setAddOn(addon: string) {
		setFilters((prev) => ({
			...prev,
			addon,
		}));
	}

	function setProductFilter(
		id: string,
		callback?: (nextState: typeof filters) => void
	) {
		setFilters((prev) => ({
			...prev,
			productId: id,
		}));

		if (callback) {
			callback({
				...filters,
				productId: id,
			});
		}
	}

	function setProviderFilter(
		provider: PricingProvider,
		callback?: (nextState: typeof filters) => void
	) {
		const newRegion = getPricingRegionForProvider(
			dataByProvider,
			provider,
			pricingDefaultFilters
		);

		const newAddon = getPricingAddonForProviderAndRegion(
			dataByProvider,
			provider,
			newRegion
		);

		setFilters((prev) => ({
			...prev,
			provider,
			region: newRegion,
			addon: newAddon,
		}));

		if (callback) {
			callback({
				...filters,
				provider,
				region: newRegion,
			});
		}
	}

	function setRegionFilter(
		region: string,
		callback?: (nextState: typeof filters) => void
	) {
		const newAddon = getPricingAddonForProviderAndRegion(
			dataByProvider,
			filters.provider,
			region
		);

		setFilters((prev) => ({
			...prev,
			region,
			addon: newAddon,
		}));

		if (callback) {
			callback({
				...filters,
				region,
			});
		}
	}

	return {
		filters,
		setProductFilter,
		setProviderFilter,
		setRegionFilter,
		setAddOn,
		selectedPlans,
		providerRegionsGroupedByLocation,
		addons,
		providers,
	};
}

const TieredStorageAddon: Addon = {
	type: "tiered-storage",
	pricePerGB: 0.00012,
};

function AllPlans({
	selectedPlans,
	addons,
}: {
	selectedPlans: PlanData[];
	addons: Addon[];
}) {
	return (
		<>
			<ClientOnly>
				{() => (
					<React.Suspense fallback={<p>Loading pricing ...</p>}>
						<PricingTable plans={selectedPlans} addons={addons} />
					</React.Suspense>
				)}
			</ClientOnly>
			<Spacer size="layout3" />
		</>
	);
}

export function PricingFooter({ filters }: { filters: PricingFilter }) {
	const { t } = useSharedContent(Dictionary.PRICING);
	return (
		<div className="text-center [&_p]:!text-sm">
			{filters.productId === "kafka" ? (
				<MarkDown content={t("kpfDisclaimer")} fullWidth={true} />
			) : (
				<MarkDown
					content={supplant(t("pricingCalculatorText"), {
						productId: filters.productId,
					})}
					fullWidth={true}
				/>
			)}
			<MarkDown content={t("contactDisclaimer")} fullWidth={true} />
		</div>
	);
}

function ByoaNote() {
	const { t } = useSharedContent(Dictionary.PRICING);
	return (
		<Alert
			title={t("byoaTitle")}
			children={
				<div className="[&>*>.markdown-prose>p]:text-secondary [&>*>.markdown-prose>p]:text-sm">
					<MarkDown content={t("byoaDesc")} fullWidth={true} />
				</div>
			}
		/>
	);
}

export function PlanComparision({
	showLoading,
	selectedProduct,
	planComparisonData,
	tableData,
	signupParams,
	variant,
}: {
	showLoading: boolean;
	selectedProduct?: ProductItemType;
	planComparisonData?: PlanComparisonType;
	tableData: GroupedPlansData;
	signupParams: ProductSignupParams;
	variant: "all-services" | "single-service";
}) {
	const { t } = useSharedContent(Dictionary.PRICING);

	const [showPlanComparison, setShowPlanComparison] = React.useState(
		variant === "all-services"
	);

	if (!planComparisonData || !selectedProduct) {
		return null;
	}
	return (
		<>
			<Spacer size="layout3" />
			<button
				aria-expanded={showPlanComparison}
				className="hover-underline-expand mx-auto flex items-center justify-between gap-3"
				onClick={() =>
					setShowPlanComparison((prevOpen) => {
						spTrackWebInteraction({
							object: "compare plans",
							action: "toggle",
							value: !prevOpen ? "open" : "close",
						});

						return !prevOpen;
					})
				}
			>
				<H3 as="h4" data-label="link-title">
					{t("comparePlans", "Compare plans")}
				</H3>
				<Icon
					name="chevron-down"
					width="16"
					height="16"
					color="primary"
					className={clsx("transition-transform", {
						"-rotate-180": showPlanComparison,
					})}
				/>
			</button>

			{showPlanComparison ? (
				<div className="mt-7">
					<PlanComparison
						loading={showLoading}
						product={{
							title: selectedProduct.title,
							iconProps: selectedProduct.iconProps,
						}}
						data={planComparisonData}
						pricingData={tableData}
						signupParams={signupParams}
					/>
					<Spacer size="layout1" />
				</div>
			) : null}
		</>
	);
}

export function handleAddOnSelect(
	planGroupName: string,
	type: string,
	selectedAddOns: string[],
	setSelectedAddOns: Dispatch<SetStateAction<string[]>>,
	filters: PricingFilter
) {
	const addOn = `${planGroupName}-${type}`;

	let updatedAddOns: Array<string> = [];
	if (selectedAddOns.includes(addOn)) {
		updatedAddOns = selectedAddOns.filter(
			(currentAddOn) => currentAddOn !== addOn
		);
	} else {
		updatedAddOns = [...selectedAddOns, addOn];
	}

	setSelectedAddOns(updatedAddOns);

	spTrackPricing({
		selector: "addon",
		service: filters.productId,
		cloud: filters.provider,
		region: filters.region,
		addOns: updatedAddOns,
	});
}

export function PlanCards({
	tableData,
	signupParams,
	filters,
	addons,
	showLoading,
	planFeatureData,
}: {
	tableData: GroupedPlansData;
	signupParams: ProductSignupParams;
	filters: PricingFilter;
	addons: Addon[];
	showLoading: boolean;
	planFeatureData?: {
		lang: string;
		plans: {
			name: "Free" | "Hobbyist" | "Startup" | "Business" | "Premium";
			features: string[];
		}[];
	};
}) {
	const [selectedAddOns, setSelectedAddOns] = React.useState<Array<string>>([]);

	const numOfPlans = Object.keys(tableData).length;

	const shouldUseGridLayout = numOfPlans > 1;

	return (
		<ul
			aria-label="pricing-plans"
			className={clsx(
				"gap-5",
				{ "flex justify-center": !shouldUseGridLayout },
				{ "grid grid-cols-1 md:grid-cols-2": shouldUseGridLayout },
				{ "lg:grid-cols-2": numOfPlans === 2 },
				{ "lg:grid-cols-3": numOfPlans === 3 },
				{ "lg:grid-cols-4": numOfPlans === 4 },
				{ "lg:grid-cols-5": numOfPlans === 5 }
			)}
		>
			{Object.entries(tableData).map(([planName, group], index) => {
				const planFeature = planFeatureData
					? planFeatureData.plans.find((plan) => plan.name === planName)
					: undefined;

				const signupUrl = getSignupUrl({
					...signupParams,
					plan: planName,
				});

				const avaialbleAddOns = [...addons];

				// Add tiered storaged to Kafka but not Startup plan
				if (
					filters.productId === "kafka" &&
					!["Startup", "Hobbyist"].includes(planName)
				) {
					avaialbleAddOns.push(TieredStorageAddon);
				}

				if (filters.productId === "clickhouse") {
					avaialbleAddOns.push(TieredStorageAddon);
				}

				return showLoading ? (
					<PlanCardSkeleton key={index} highLighted={planName === "Business"} />
				) : (
					<li
						key={index}
						aria-label={`plan-${planName}`}
						className={clsx("flex-1", {
							"max-w-[410px]": numOfPlans === 1,
						})}
					>
						<PlanCard
							addons={avaialbleAddOns}
							onAddOnSelect={(planGroupName: string, type: string) =>
								handleAddOnSelect(
									planGroupName,
									type,
									selectedAddOns,
									setSelectedAddOns,
									filters
								)
							}
							planGroup={group}
							highLighted={planName === "Business"}
							planFeature={planFeature}
							signupUrl={signupUrl}
							service={filters.productId}
						/>
					</li>
				);
			})}
		</ul>
	);
}

function PricingFilters({
	isAllServiceVariant,
	productsData,
	handleProductChange,
	filters,
	handleProviderChange,
	handleRegionChange,
	providerRegionsGroupedByLocation,
	providers,
}: {
	isAllServiceVariant: boolean;
	productsData: ProductItemType[];
	handleProductChange: (id: string) => void;
	filters: PricingFilter;
	handleProviderChange: (e: React.FormEvent<HTMLSelectElement>) => void;
	handleRegionChange: (e: React.FormEvent<HTMLSelectElement>) => void;
	providerRegionsGroupedByLocation: {
		[key: string]: {
			id: string;
			shortId: string;
			location: string;
			plans: Array<PlanData>;
			addons: Addon[];
		}[];
	};
	providers: string[];
}) {
	const { t } = useSharedContent(Dictionary.PRICING);

	return (
		<div className="bg-secondary rounded-md p-6">
			{isAllServiceVariant ? (
				<>
					<div className="mx-auto mb-3 max-w-content text-center">
						<MarkDown
							content={t("serviceTitle", "The complete Aiven platform")}
							fullWidth={true}
						/>
					</div>

					<Paragraph size="body-small" color="secondary" as="h2">
						{t("service", "Service")}
					</Paragraph>

					<div className="mb-5">
						<>
							<div className="bg-primary border-stroke hidden rounded-md border p-6 md:block">
								<ServiceSelect
									productsData={productsData}
									onChange={handleProductChange}
									value={filters.productId}
								/>
							</div>

							<div className="block md:hidden">
								{
									<ServiceSelectMobile
										productsData={productsData}
										onChange={handleProductChange}
										value={filters.productId}
									/>
								}
							</div>
						</>
					</div>
				</>
			) : null}

			<div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
				<div>
					<Label htmlFor="cloud">{t("cloud", "Cloud")}</Label>
					<Select
						id="cloud"
						value={filters.provider}
						onChange={handleProviderChange}
					>
						{providers.map((provider) => (
							<Option key={provider} value={provider}>
								{provider}
							</Option>
						))}
					</Select>
				</div>

				<div>
					<Label htmlFor="region">{t("region", "Region")}</Label>
					<Select
						id="region"
						value={filters.region}
						onChange={handleRegionChange}
					>
						{Object.keys(providerRegionsGroupedByLocation).map((location) => {
							return (
								<OptGroup key={location} label={location}>
									{providerRegionsGroupedByLocation[location].map(
										({ id, shortId, location }) => (
											<Option
												key={id}
												value={id}
											>{`${location} (${shortId})`}</Option>
										)
									)}
								</OptGroup>
							);
						})}
					</Select>
				</div>
			</div>
		</div>
	);
}

export function Pricing({
	pricingRelatedData,
	variant = "all-services",
	defaultCloud,
}: Props) {
	const {
		filters,
		setProductFilter,
		setProviderFilter,
		setRegionFilter,
		selectedPlans,
		addons,
		providerRegionsGroupedByLocation,
		providers,
	} = usePricingFilter(pricingRelatedData, defaultCloud);

	const locale = useLocale();

	const navigation = useNavigation();

	const showLoading = useSpinDelay(navigation.state === "loading", {
		delay: 200,
		minDuration: 400,
	});

	const isAllServiceVariant = variant === "all-services";

	const { servicesData, planFeatureData, planComparisonData } =
		pricingRelatedData;

	const [searchParams] = useSearchParams();

	const showAllPlanPricingTable = searchParams.get("plan") === "all";

	const productsData = servicesData
		.filter(
			(service) => service.externalId != undefined && service.group != undefined
		)
		.map<ProductItemType>((service) => ({
			externalId: asText(service.externalId?.current),
			title: `Aiven for ${asText(service.title)}`,
			iconProps: getImageProps(
				getImageBuilder(service.icon, {
					alt: `Aiven for ${asText(service.title)} logo`,
				})
			),
			group: asText(service.group),
			locale,
			itemUrl: "/pricing",
		}));

	const selectedProduct = React.useMemo(() => {
		return productsData.find(
			(product) => product.externalId === filters.productId
		);
	}, [productsData, filters.productId]);

	const signupParams: ProductSignupParams = {
		service: filters.productId,
		cloud: filters.provider,
	};

	useUpdateQueryStringValueWithoutNavigation("product", filters.productId, {
		enabled: isAllServiceVariant,
	});

	const tableData = getPricingTableData({ plans: selectedPlans });

	function handleProductChange(id: string) {
		setProductFilter(id, () => {
			spTrackPricing({
				selector: "service",
				service: id,
				cloud: filters.provider,
				region: filters.region,
			});
		});
	}

	function handleProviderChange(e: React.FormEvent<HTMLSelectElement>) {
		const newProvider = e.currentTarget.value as PricingProvider;

		setProviderFilter(newProvider, (updatedFilters) => {
			spTrackPricing({
				selector: "cloud",
				service: updatedFilters.productId,
				cloud: updatedFilters.provider,
				region: updatedFilters.region,
			});
		});
	}

	function handleRegionChange(e: React.FormEvent<HTMLSelectElement>) {
		const newRegion = e.currentTarget.value;

		setRegionFilter(newRegion, (updatedFilters) => {
			spTrackPricing({
				selector: "region",
				service: updatedFilters.productId,
				cloud: updatedFilters.provider,
				region: newRegion,
			});
		});
	}

	return (
		<>
			<PricingFilters
				filters={filters}
				handleRegionChange={handleRegionChange}
				providers={providers}
				providerRegionsGroupedByLocation={providerRegionsGroupedByLocation}
				handleProviderChange={handleProviderChange}
				handleProductChange={handleProductChange}
				isAllServiceVariant={isAllServiceVariant}
				productsData={productsData}
			/>
			<Spacer size="layout3" />
			{showAllPlanPricingTable ? (
				<AllPlans addons={addons} selectedPlans={selectedPlans} />
			) : null}
			<PlanCards
				planFeatureData={planFeatureData}
				signupParams={signupParams}
				showLoading={showLoading}
				filters={filters}
				tableData={tableData}
				addons={addons}
			/>
			<Spacer size="layout3" />
			<PricingFooter filters={filters} />

			<PlanComparision
				showLoading={showLoading}
				selectedProduct={selectedProduct}
				planComparisonData={planComparisonData}
				tableData={tableData}
				signupParams={signupParams}
				variant={variant}
			/>

			{isAllServiceVariant ? <ByoaNote /> : null}
		</>
	);
}
