import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/sanity/images";
import { asLink, asText } from "~/sanity/sanity-helpers";
import type { Testimonial as TestimonialType } from "~/types/sanity-schema";
import { TestimonialList as TestimonialComponent } from "../testimonial";
import type { TestimonialItemProps } from "../testimonial";
import { useSharedContent } from "~/hooks/localization";
import { notEmpty } from "~/utils/misc";

export function Testimonial({
	title,
	subtitle,
	tagline,
	items,
	ctaLink,
	variant = "media",
	isCarousel,
	isHideItemLink = false,
	showCompanyLogoAndTags = false,
	isHideMedia = false,
}: TestimonialType) {
	const { t } = useSharedContent();

	const testimonialItems = items
		? items
				.map<TestimonialItemProps | null>((item) => {
					const type = item._type;

					switch (type) {
						case "testimonialItem": {
							const tags = item.company
								? item.company.tags?.map((tag) => ({
										type: tag._type,
										title:
											tag._type === "service"
												? asText(tag.title)
												: asText(tag.label),
									}))
								: [];

							const logoImgSrc = getImageProps(
								getImageBuilder(item.company?.companyLogo, {
									alt: getImageAltProp(item.company?.companyLogo, {
										fallback: `${item.company?.companyName} logo`,
									}),
								})?.height(
									item.company?.logoHeight ? item.company?.logoHeight : 80
								)
							);

							return {
								image: getImageProps(
									getImageBuilder(item.image, {
										alt: getImageAltProp(item?.image),
									})?.height(300)
								),
								ytId: item.ytId,
								tagline: item.tagline,
								name: item.name,
								jobTitle: item.jobTitle,
								content: item.content,
								cta: {
									url: asLink(item.ctaLink),
									title: item.ctaLink?.title,
								},
								tags,
								companyLogo: logoImgSrc,
								logoHeight: item.company?.logoHeight,
							};
						}
						case "quote": {
							const tags = item.company
								? item.company.tags?.map((tag) => ({
										type: tag._type,
										title:
											tag._type === "service"
												? asText(tag.title)
												: asText(tag.label),
									}))
								: [];

							const mediaImgSrc = getImageProps(
								getImageBuilder(item.image, {
									alt: getImageAltProp(item?.image),
								})?.height(300)
							);

							const logoImgSrc = getImageProps(
								getImageBuilder(item.company?.companyLogo, {
									alt: getImageAltProp(item.company?.companyLogo, {
										fallback: `${item.company?.companyName} logo`,
									}),
								})?.height(
									item.company?.logoHeight ? item.company?.logoHeight : 80
								)
							);

							return {
								image:
									variant === "media" || variant === "default"
										? mediaImgSrc
										: logoImgSrc,
								logoHeight: item.company?.logoHeight,
								name: item.name,
								jobTitle: item.jobTitle,
								ytId: item.ytId,
								content: item.content,
								cta: {
									url: item.caseStudy?.slug?.current,
									title: t("readCaseStudy"),
								},
								tags,
								companyLogo: logoImgSrc,
							};
						}
						case "person": {
							return {
								image: getImageProps(
									getImageBuilder(item.image, {
										alt: getImageAltProp(item?.image, {
											fallback: `${item.name} portrait`,
										}),
									})?.height(80)
								),
								name: item.name,
								jobTitle: item.jobTitle,
								ytId: item.ytId,
								content: item.content,
							};
						}

						default:
							return null;
					}
				})
				.filter(notEmpty)
		: [];

	return (
		<>
			<TestimonialComponent
				variant={variant}
				tagline={tagline}
				title={asText(title)}
				subtitle={subtitle}
				items={testimonialItems}
				cta={{
					title: ctaLink?.title,
					url: asLink(ctaLink),
				}}
				isCarousel={isCarousel}
				isHideItemLink={isHideItemLink}
				showCompanyLogoAndTags={showCompanyLogoAndTags}
				isHideMedia={isHideMedia}
			/>
		</>
	);
}
