import * as Tabs from "@radix-ui/react-tabs";
import { Paragraph } from "~/components/ui/typography";
import { Image } from "~/components/ui/image";
import type { ImageProps } from "~/components/ui/image";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "motion/react";
import { spTrackWebInteraction } from "~/utils/tracking";
import { useSearchParams } from "react-router";
import {
	scrollIntoViewOffset,
	useUpdateQueryStringValueWithoutNavigation,
} from "~/utils/hooks";

export type TabItem = {
	id: string;
	label: string;
	content: React.ReactNode;
	image?: ImageProps;
	imageHeight?: number;
};

interface Props {
	items: Array<TabItem>;
	variant?: "default" | "button-toggle";
}

const getInitialSelectedTabValue = (
	items: TabItem[],
	tabParam: string | null
) => {
	if (tabParam && items.some((item) => item.id === tabParam)) {
		return tabParam;
	}
	return items[0].id;
};

const transition = {
	type: "tween",
	ease: "easeOut",
	duration: 0.15,
};

const navHeight = 100;

const defaultTabClassNames =
	"py-3 px-5 active:bg-transparent border-b-2 border-stroke [&>p]:data-[state=active]:font-medium data-[state=active]:border-b-theme-primary hover:border-b-theme-primary hover:text-primary";

export function Tab({ items, variant = "default" }: Props) {
	const tabParam = useSearchParams()[0].get("t");

	const [chosenVal, setChosenVal] = useState(
		getInitialSelectedTabValue(items, tabParam)
	);

	const [buttonRefs, setButtonRefs] = useState<Array<HTMLDivElement | null>>(
		[]
	);

	useEffect(() => {
		setButtonRefs((prev) => prev.slice(0, items.length));
	}, [items.length]);

	const navRef = useRef<HTMLDivElement>(null);
	const navRect = navRef.current?.getBoundingClientRect();

	const [hoveredTabIndex, setHoveredTabIndex] = useState<number | null>(null);

	const hoveredRect =
		buttonRefs[hoveredTabIndex ?? -1]?.getBoundingClientRect();

	const isUserInteraction = useRef(false);

	// Update the URL without navigation
	useUpdateQueryStringValueWithoutNavigation("t", chosenVal);

	function handleChange(tabKey: string) {
		isUserInteraction.current = true;
		setChosenVal(tabKey);

		spTrackWebInteraction({
			object: "tab",
			action: "switch",
			value: tabKey,
		});
	}

	useEffect(() => {
		// Only scroll to the tab section on page load
		if (!isUserInteraction.current && tabParam) {
			const element = document.getElementById(tabParam);
			if (element) {
				scrollIntoViewOffset(element, navHeight);
			}
		}
		isUserInteraction.current = false;
	}, [tabParam]);

	if (variant === "default") {
		return (
			<Tabs.Root
				orientation="vertical"
				defaultValue={items[0].id}
				value={chosenVal}
				onValueChange={handleChange}
			>
				<Tabs.List className="max-w-full overflow-x-auto whitespace-nowrap lg:flex lg:justify-center">
					{items.map((item) => {
						const { id, label, image, imageHeight = 48 } = item;

						return (
							<Tabs.Trigger
								className={clsx(defaultTabClassNames, {
									"items-center justify-center lg:flex lg:flex-1": image?.src,
									"lg:flex-1": items.length > 4,
								})}
								key={id}
								value={id}
								id={id}
								aria-label={label}
							>
								{image && image?.src ? (
									<Image
										className="mx-0 my-auto w-auto"
										style={{
											maxHeight: `${imageHeight}px`,
										}}
										{...image}
									/>
								) : (
									<Paragraph size="body-small">{label}</Paragraph>
								)}
							</Tabs.Trigger>
						);
					})}
				</Tabs.List>
				<>
					{items.map((item) => (
						<Tabs.Content
							key={item.id}
							value={item.id}
							aria-labelledby={item.id}
							className="data-[state=active]:animate-fadeInBounceUp"
						>
							{item.content}
						</Tabs.Content>
					))}
				</>
			</Tabs.Root>
		);
	} else {
		return (
			<div className="mx-auto">
				<Tabs.Root
					defaultValue={items[0].id}
					value={chosenVal}
					onValueChange={handleChange}
				>
					<div className="mb-6 text-center">
						<Tabs.List
							className="relative -mx-layout2 inline-block overflow-x-auto whitespace-nowrap"
							ref={navRef}
							onMouseLeave={() => {
								setHoveredTabIndex(null);
							}}
						>
							{items.map((item, i) => {
								const { id, label } = item;
								return (
									<Tabs.Trigger
										key={`${i}_${id}`}
										value={id}
										id={id}
										className={clsx(
											{
												"rounded-l-2xl": i === 0, // first item
											},
											{
												"rounded-r-2xl": i === items.length - 1, // last item
											}
										)}
										style={{
											background: "rgba(165, 168, 184, 0.2)", // color-grey-50 with opacity 20%
										}}
									>
										<motion.div
											onMouseEnter={() => {
												setHoveredTabIndex(i);
											}}
											onFocus={() => {
												setHoveredTabIndex(i);
											}}
											className={clsx(
												{
													"bg-secondary-50": chosenVal === id,
												},
												{
													"bg-transparent": chosenVal !== id,
												},
												"relative z-20 inline-flex size-full min-h-7 cursor-pointer select-none items-center justify-between overflow-hidden rounded-2xl focus-visible:outline-blue-40"
											)}
											ref={(el) => {
												buttonRefs[i] = el;
											}}
										>
											<Paragraph
												fontWeight="font-medium"
												size="body-small"
												className="bg-transparent p-4"
												color={chosenVal === id ? "inverse" : "primary"}
											>
												{label}
											</Paragraph>
										</motion.div>
									</Tabs.Trigger>
								);
							})}
							<AnimatePresence>
								{hoveredRect && navRect && (
									<motion.div
										key="hover"
										id="hover"
										transition={transition}
										className="absolute left-0 top-0 z-10 rounded-2xl"
										style={{
											background: "rgba(165, 168, 184, 0.4)", // color-grey-50 with opacity 40%
										}}
										initial={{
											x: hoveredRect.left - navRect.left,
											y: hoveredRect.top - navRect.top,
											width: hoveredRect.width,
											height: hoveredRect.height,
											opacity: 0,
										}}
										animate={{
											x: hoveredRect.left - navRect.left,
											y: hoveredRect.top - navRect.top,
											width: hoveredRect.width,
											height: hoveredRect.height,
											opacity: 1,
										}}
										exit={{
											x: hoveredRect.left - navRect.left,
											y: hoveredRect.top - navRect.top,
											width: hoveredRect.width,
											height: hoveredRect.height,
											opacity: 0,
										}}
									/>
								)}
							</AnimatePresence>
						</Tabs.List>
					</div>
					<>
						{items.map((item, i) => (
							<Tabs.Content
								key={`${i}_${item.id}_content`}
								value={item.id}
								aria-labelledby={item.id}
								className="data-[state=active]:animate-fadeInBounceUp"
							>
								{item.content}
							</Tabs.Content>
						))}
					</>
				</Tabs.Root>
			</div>
		);
	}
}
