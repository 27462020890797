import { useRef, useState, useEffect } from "react";
import { Icon } from "~/components/ui/icons";
import { Paragraph } from "~/components/ui/typography";
import type { ScrollSpyNavigation } from "~/types/sanity-schema";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/sanity/images";
import { DEFAULT_SERVICE_ICON_HEIGHT } from "~/utils/media-height";
import { asLink, asText } from "~/sanity/sanity-helpers";
import { spTrackWebInteraction } from "~/utils/tracking";
import type { ScrollSpyNavItem } from "../scrollspy-navigation";
import { ScrollSpyNavigation as ScrollSpyNavigationComponent } from "../scrollspy-navigation";
import { Container } from "../ui/container";
import clsx from "clsx";

export function ScrollSpyNav({
	primaryCTA,
	secondaryCTA,
	items,
	title,
	logo,
	logoLink,
	variant,
}: ScrollSpyNavigation) {
	const navItems = items
		? items.map<ScrollSpyNavItem>((item) => ({
				title: asText(item.title),
				sectionId: asText(item.sectionId),
			}))
		: [];

	const navBackToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		spTrackWebInteraction({
			object: "navigation",
			action: "click",
			value: "back to top",
		});
	};

	const [isSticky, setIsSticky] = useState(false);
	const navRef = useRef<HTMLElement | null>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (navRef.current) {
				const navTop = navRef.current.getBoundingClientRect().top;
				setIsSticky(navTop <= 0);
			}
		};

		window.addEventListener("scroll", handleScroll);
		handleScroll();

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<nav
				ref={navRef}
				aria-label={`${title}, secondary`}
				className={clsx("bg-primary sticky top-0 hidden w-full xl:block", {
					"z-50 border-stroke border-b": isSticky,
				})}
			>
				<Container noPadding className="px-6">
					<ScrollSpyNavigationComponent
						variant={variant}
						navItems={navItems}
						logoImgProps={getImageProps(
							getImageBuilder(logo, {
								alt: getImageAltProp(logo),
							})?.height(DEFAULT_SERVICE_ICON_HEIGHT)
						)}
						logoLink={{
							title: logoLink?.title || title,
							url: asLink(logoLink),
						}}
						primaryCTA={{
							title: primaryCTA?.title,
							url: asLink(primaryCTA),
							trackingPosition: "scrollspy",
						}}
						secondaryCTA={{
							title: secondaryCTA?.title,
							url: asLink(secondaryCTA),
							trackingPosition: "scrollspy",
						}}
					/>
				</Container>
			</nav>
			<button
				title="Scoll to top"
				onClick={navBackToTop}
				className={clsx(
					"bg-primary border-button-secondary fixed bottom-layout4 right-6 hidden size-layout4 flex-col items-center justify-center gap-1 rounded-sm border opacity-0 transition-opacity duration-300 xl:flex",
					{
						"opacity-100 z-50": isSticky,
					}
				)}
			>
				<Icon
					name="arrow-up"
					color="primary"
					className="size-[14px] shrink-0"
				/>
				<Paragraph size="body-xsmall">Top</Paragraph>
			</button>
		</>
	);
}
